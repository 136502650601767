<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/tabs/procedures"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.procedureFiles.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="onFilterModal">
            <ion-icon slot="icon-only" :icon="filterOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="procedureFile in procedureFiles"
        :key="procedureFile.id"
        @click="onFileModal(procedureFile)"
        button
      >
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
              <ion-thumbnail v-if="procedureFile.thumbnail">
                <img :src="procedureFile.thumbnail" />
              </ion-thumbnail>
              <ion-thumbnail v-else>
                <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
              </ion-thumbnail>
            </ion-col>
            <ion-col>
              <ion-card-title>
                {{ procedureFile.originalname }}
              </ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <p>{{ procedureFile.mimetype }}</p>
          <p>{{ formatSize(procedureFile.size) }}</p>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="procedureFiles.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="fetchMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import filesize from 'filesize.js';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  IonBackButton,
  IonThumbnail,
  IonRow,
  IonCol,
  modalController,
  toastController,
} from '@ionic/vue';
import { filterOutline } from 'ionicons/icons';

import ODNModalProcedureFilesFilters from '@m/odn-modal-procedure-files-filters.vue';
import ODNModalFile from '@m/odn-modal-file.vue';
import ODNNoContent from '@c/odn-no-content.vue';

import APIService from '@s/api.service';

export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    IonBackButton,
    IonThumbnail,
    IonRow,
    IonCol,
    ODNNoContent,
  },
  computed: {
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  data() {
    return {
      procedureFiles: [],
      filters: {
        sort: 'created',
        direction: 'desc',
      },
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      filterOutline,
      loading: true,
    };
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.procedureFiles = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    formatDate(dt) {
      return this.$dayjs(dt)
        .locale(this.$i18n.locale)
        .format('ll - LT');
    },
    formatSize(b) {
      return filesize(b);
    },
    async fetchData(clear = false) {
      try {
        this.loading = true;

        if (clear) {
          this.page = 1;
        }

        const response = await APIService.get(
          `/procedures/${this.$route.params.id}/files`,
          {
            page: this.page,
            limit: this.limit,
            search: this.searchText,
            sort: this.filters.sort,
            direction: this.filters.direction,
          },
        );

        if (clear) {
          this.procedureFiles = [];
        }

        this.procedureFiles = this.procedureFiles.concat(response.data.items);

        if (this.procedureFiles.length < response.data.meta.totalItems) {
          this.disableInfiniteScrolling = false;
        } else {
          this.disableInfiniteScrolling = true;
        }
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.files.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async fetchMoreData(event) {
      this.page++;
      await this.fetchData();
      event.target.complete();
    },
    async onSearch() {
      await this.fetchData(true);
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    async onFilterModal() {
      const modal = await modalController.create({
        component: ODNModalProcedureFilesFilters,
        componentProps: {
          filters: this.filters,
        },
      });
      await modal.present();

      // wait for a response when closing the modal
      const newFilters = (await modal.onDidDismiss()).data;

      if (newFilters) {
        this.filters = newFilters;
        this.fetchData(true);
      }
    },
    async onFileModal(procedureFile) {
      const modal = await modalController.create({
        component: ODNModalFile,
        componentProps: {
          file: procedureFile,
        },
      });
      return modal.present();
    },
  },
};
</script>
